import React from 'react';
import Layout from '../components/Layout';
import './index.sass';

const NotFoundPage = () => (
  <Layout>
    <div className="notFound">
      <h1 className="notFound__title">NOT FOUND</h1>
      <p className="notFound__content">お探しのページはありません</p>
    </div>
  </Layout>
);

export default NotFoundPage;
